// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-not-used-form-js": () => import("./../../../src/pages/not-used/form.js" /* webpackChunkName: "component---src-pages-not-used-form-js" */),
  "component---src-templates-available-homes-js": () => import("./../../../src/templates/available-homes.js" /* webpackChunkName: "component---src-templates-available-homes-js" */),
  "component---src-templates-backpage-js": () => import("./../../../src/templates/backpage.js" /* webpackChunkName: "component---src-templates-backpage-js" */),
  "component---src-templates-index-page-js": () => import("./../../../src/templates/index-page.js" /* webpackChunkName: "component---src-templates-index-page-js" */),
  "component---src-templates-single-home-js": () => import("./../../../src/templates/single-home.js" /* webpackChunkName: "component---src-templates-single-home-js" */)
}

